function Footer() {
  return (
    <footer>
      <span>Done is beautiful</span>
      <br />
      <span>&copy; 2022&ndash;Present Clint Gunter</span>
    </footer>
  );
}

export default Footer;
